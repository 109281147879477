import React from "react";
import { useContext, useEffect, useRef } from "react";
import AppContext from "../hooks/createContext";


const ImgRender = ({img}: { img: HTMLImageElement }) => {
  const divRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    img.className = 'object-cover max-w-200';
    divRef.current?.appendChild(img);

    const downloadBtn = document.createElement('div')
    downloadBtn.innerText = '下载';

    downloadBtn.addEventListener('click', () => {
      const base64 = img.src;
      const byteCharacters = atob(
      base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
    );
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: undefined,
      });
      const aLink = document.createElement("a");
      aLink.download = "图片切片.png"; //这里写保存时的图片名称
      aLink.href = URL.createObjectURL(blob);
      aLink.click();
    })

    downloadBtn.className = 'absolute right-0 bottom-0 w-full leading-[160px] bg-amber-50 text-amber-500 bg-opacity-50 opacity-0 hover:opacity-100 hover:text-amber-900 text-center cursor-pointer'

    divRef.current?.appendChild(downloadBtn);

  }, [])
  return (<div className="relative w-[0px] h-[160px] ml-4 max-w-[160px] flex-1 flex-shrink-0 shadow shadow-sm shadow-amber-400 basis-[160px] overflow-hidden rounded-xl" ref={divRef}/>)
}

export const CutImages = () => {
  const {
    cutImages
  } = useContext(AppContext)!;
  return (
    <div className="flex flex-nowrap h-[100%] w-[100%] overflow-x-auto">
      {cutImages.map((img: HTMLImageElement) => {
        return (
          <ImgRender img={img} />
        )
      })}
    </div>
  )
}

export default {};