import React, { useEffect, useState, FC } from "react";
import Uploady, {
  useItemStartListener,
  useItemFinalizeListener,
  useUploader,
} from "@rpldy/uploady";
import UploadDropZone from "@rpldy/upload-drop-zone";
import withPasteUpload from "@rpldy/upload-paste";
import UploadButton from "@rpldy/upload-button";
import axios from "axios";
import { GET_IMAGE_EMBEDDING_API, IMAGE_EMBEDDING_DOMAIN } from "../../constant";

const PasteUploadDropZone = withPasteUpload(UploadDropZone);

const UploadStatus = () => {
  const [status, setStatus] = useState('');

  useItemStartListener(() => setStatus("正在处理"));
  useItemFinalizeListener(() => setStatus("AI识别中"));

  return (<div className="text-center" />);
};

const UploadActions = ({ options }: any) => {
  useItemFinalizeListener((item) => {
    const { file } = item;

    const form = new FormData();
    form.append('file', file as File);

    axios.post(GET_IMAGE_EMBEDDING_API, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(res => res.data)
      .then(res => {
        options?.onChange({
          npy: `${IMAGE_EMBEDDING_DOMAIN}${res.pathname}`,
          file,
        })
      })

  });

  return null;
};

export const Uploader: FC<Record<string, any>> = (props) => {

  return (
    <Uploady accept={"image/*"}>
      <PasteUploadDropZone params={{test: "paste"}} className="upload-area">
        <UploadButton>
          点击选择图片
        </UploadButton>
      </PasteUploadDropZone>
      <UploadActions options={props} />
      <UploadStatus />
    </Uploady>
  )
}

export default {}
